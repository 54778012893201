<template>
  <div class="relative">
    <div class="h-[72.5vw] md:h-[33.875vw] bg-red w-full relative overflow-hidden">
      <div class="flex justify-center pt-[16vw] md:pt-[3.5vw]">
        <SvgTtfLogo class="text-white w-[30vw] md:w-[12.5vw] opacity-0"
                    ref="logo"
        />
      </div>
      <SvgShapeStar ref="star"
                    class="absolute top-[10vw] md:-top-[3.5vw] -left-[10vw] md:left-[15vw] w-[30vw] md:w-[15vw] text-yellow"
                    :animate="false"
      />
      <SvgShapeCrescent ref="crescent"
                        class="absolute top-[18vw] md:top-[2vw] w-[20.7vw] md:w-[10vw] text-turquoise -right-[7vw] md:-right-[1vw]"
                        :rotate="-60"
                        :animate="false"
      />
      <SvgShapeDiamond ref="diamond"
                       class="absolute hidden md:block w-[11vw] text-orange top-[13vw] -left-[3vw]"
                       :rotate="-35"
                       :animate="false"
      />
    </div>

    <div class="w-full h-full pointer-events-none absolute top-[53.5vw] md:top-[17.5vw] z-10">
      <video autoplay
             playsinline
             disableremoteplayback="true"
             muted
             class="md:-mt-[4.5vw] w-full pointer-events-auto"
      >
        <source v-if="size.width > 0 && size.isMobile"
                src="/video/intro_hevc_mobile.mov"
                type="video/mp4; codecs=&quot;hvc1&quot;"
        >
        <source v-else-if="size.width > 0"
                src="/video/intro_hevc_desktop.mov"
                type="video/mp4; codecs=&quot;hvc1&quot;"
        >
        <source v-if="size.width > 0 && size.isMobile"
                src="/video/intro_TTF.webm"
                type="video/webm"
        >
        <source v-else-if="size.width > 0"
                src="/video/intro_TTF_wide.webm"
                type="video/webm"
        >
      </video>
    </div>

    <div class="-mt-px bg-indigo">
      <h1 class="pointer-events-none z-20 block font-work text-white font-extrabold text-[12vw] md:text-[7.5vw] leading-none text-center pt-[18vw] md:pt-[14.125vw] relative md:max-w-[52vw] mx-auto tracking-[-0.02em] px-[5vw] md:px-0">
        <AnimateFadeIn>
          {{ title }}
        </AnimateFadeIn>
      </h1>
      <div class="flex justify-center mt-[6vw] md:mt-[3.5vw] z-20">
        <AnimateFadeIn>
          <ButtonRegular v-bind="cta">
            {{ cta.label }}
          </ButtonRegular>
        </AnimateFadeIn>
      </div>
      <div style="background: linear-gradient(to bottom, transparent 50%, white 50%);"
           class="mt-[13.5vw] md:mt-[6vw]"
      >
        <AnimateFadeIn v-if="cards && cards.length > 0">
          <Slider :per-view-md="5"
                  title=""
          >
            <Card v-for="(card, index) in cards"
                  class="keen-slider__slide"
                  :key="index"
                  v-bind="card"
            />
          </Slider>
        </AnimateFadeIn>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { screenSize } from '~/composables/screen-size';
import { animate, scroll } from 'motion';
import { type CtaType, type CardType } from '../../../types';

type SectionHomepageHeaderProps = {
  title: string
  cta: CtaType,
  cards: CardType[]
}

defineProps<SectionHomepageHeaderProps>();

const size = screenSize();
const star = ref(null);
const diamond = ref(null);
const logo = ref(null);
const crescent = ref(null);
const lastYPosition = ref(0);

const pxToVw = (px:number):number => {
  return px/size.value.width*100;
};

onMounted(() => {
  animate(logo.value.$el,
    { opacity: 1 },
    { duration:3, delay: 1.5 }
  );

  scroll(
    ({ y }) => {
      // We return early if element is not present, this is to avoid errors, when on other screens
      // since the event is still triggered
      if (!star.value) return;

      animate(star.value.$el,
        { transform: `translateY(${pxToVw(y.current / 2)}vw) rotate(${y.current}deg)` },
        { duration:0 }
      );

      animate(crescent.value.$el,
        { transform: `translateY(${pxToVw(y.current / 10)}vw) translateX(${pxToVw(y.current / 7)}vw) rotate(${(-y.current/6 -60)}deg)`
        }, { duration:0 }
      );

      animate(diamond.value.$el,
        { transform: `translateX(${-pxToVw(y.current / 10)}vw) rotate(-35deg)` },
        { duration:0 }
      );

      lastYPosition.value = y.current;
    }
  );
});
</script>
